"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdaptyError = exports.adapty = exports.activateAdapty = void 0;
var tslib_1 = require("tslib");
var activate_1 = require("./activate");
Object.defineProperty(exports, "activateAdapty", { enumerable: true, get: function () { return activate_1.activateAdapty; } });
var adapty_1 = require("./adapty");
Object.defineProperty(exports, "adapty", { enumerable: true, get: function () { return adapty_1.adapty; } });
var error_1 = require("./sdk/error");
Object.defineProperty(exports, "AdaptyError", { enumerable: true, get: function () { return error_1.AdaptyError; } });
tslib_1.__exportStar(require("./sdk/types"), exports);
