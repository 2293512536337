"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Promo = void 0;
var tslib_1 = require("tslib");
var react_native_1 = require("react-native");
var error_1 = require("./error");
var Promo = /** @class */ (function () {
    function Promo(context) {
        this.ctx = context;
    }
    /**
     * @description Returns true if a sheet that enables users
     * to redeem subscription offer codes can be displayed.
     */
    Promo.prototype.canPresentCodeRedemptionSheet = function () {
        return react_native_1.Platform.OS === "ios" && parseInt(react_native_1.Platform.Version, 10) >= 14;
    };
    /**
     * @description Displays a sheet that enables users
     * to redeem subscription offer codes that you generated in App Store Connect.
     */
    Promo.prototype.presentCodeRedemptionSheet = function () {
        if (react_native_1.Platform.OS !== 'ios') {
            throw new error_1.AdaptyError({
                adaptyCode: 'badRequest',
                localizedDescription: 'presentCodeRedemptionSheet is an iOS-only method',
                code: 400,
            });
        }
        (0, error_1.isSdkAuthorized)(this.ctx.isActivated);
        try {
            this.ctx.module.presentCodeRedemptionSheet();
        }
        catch (error) {
            throw (0, error_1.attemptToDecodeError)(error);
        }
    };
    Promo.prototype.getPromo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var json, result, error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, error_1.isSdkAuthorized)(this.ctx.isActivated);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.ctx.module.getPromo()];
                    case 2:
                        json = _a.sent();
                        result = JSON.parse(json);
                        return [2 /*return*/, result];
                    case 3:
                        error_2 = _a.sent();
                        throw (0, error_1.attemptToDecodeError)(error_2);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return Promo;
}());
exports.Promo = Promo;
