"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdaptyPaywalls = void 0;
var tslib_1 = require("tslib");
var error_1 = require("./error");
var AdaptyPaywalls = /** @class */ (function () {
    function AdaptyPaywalls(context) {
        this.ctx = context;
    }
    AdaptyPaywalls.prototype.setFallback = function (paywalls) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.ctx.module.setFallbackPaywalls(paywalls)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                    case 2:
                        error_2 = _a.sent();
                        throw (0, error_1.attemptToDecodeError)(error_2);
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AdaptyPaywalls.prototype.logShow = function (variationId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.ctx.module.logShowPaywall(variationId)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                    case 2:
                        error_3 = _a.sent();
                        throw (0, error_1.attemptToDecodeError)(error_3);
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * @returns
     * Object of
     */
    AdaptyPaywalls.prototype.getPaywalls = function (options) {
        if (options === void 0) { options = {}; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var json, result, error_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, error_1.isSdkAuthorized)(this.ctx.isActivated);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.ctx.module.getPaywalls(options)];
                    case 2:
                        json = _a.sent();
                        result = JSON.parse(json);
                        // console.log('\n-------------------\nPAYWALLS RESULT:');
                        // result.paywalls.forEach((paywall) => console.log(Object.keys(paywall), paywall.developerId));
                        // result.products.forEach((product) => console.log(Object.keys(product), product.vendorProductId));
                        // console.log('\n-------------------\nPAYWALLS END!');
                        return [2 /*return*/, result];
                    case 3:
                        error_4 = _a.sent();
                        throw (0, error_1.attemptToDecodeError)(error_4);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return AdaptyPaywalls;
}());
exports.AdaptyPaywalls = AdaptyPaywalls;
