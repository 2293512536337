"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Profile = void 0;
var tslib_1 = require("tslib");
var error_1 = require("./error");
var Profile = /** @class */ (function () {
    function Profile(context) {
        this.ctx = context;
    }
    /**
     * Use identify to set userID to a current session
     *
     * @returns Promised
     * @throws AdaptyError
     */
    Profile.prototype.identify = function (customerUserId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, error_1.isSdkAuthorized)(this.ctx.isActivated);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.ctx.module.identify(customerUserId)];
                    case 2:
                        result = _a.sent();
                        return [2 /*return*/, result];
                    case 3:
                        error_2 = _a.sent();
                        throw (0, error_1.attemptToDecodeError)(error_2);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Updates any available fields to a current user
     * @returns Promised
     * @throws AdaptyError
     */
    Profile.prototype.update = function (updatedFields) {
        var _a;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, error_3;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        (0, error_1.isSdkAuthorized)(this.ctx.isActivated);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        if (updatedFields.hasOwnProperty('birthday')) {
                            updatedFields.birthday = (_a = updatedFields.birthday) === null || _a === void 0 ? void 0 : _a.toISOString();
                        }
                        return [4 /*yield*/, this.ctx.module.updateProfile(updatedFields)];
                    case 2:
                        result = _b.sent();
                        return [2 /*return*/, result];
                    case 3:
                        error_3 = _b.sent();
                        throw (0, error_1.attemptToDecodeError)(error_3);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Drops a user connected to the session
     *
     * @throws AdaptyError
     */
    Profile.prototype.logout = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, error_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, error_1.isSdkAuthorized)(this.ctx.isActivated);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.ctx.module.logout()];
                    case 2:
                        result = _a.sent();
                        return [2 /*return*/, result];
                    case 3:
                        error_4 = _a.sent();
                        throw (0, error_1.attemptToDecodeError)(error_4);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return Profile;
}());
exports.Profile = Profile;
