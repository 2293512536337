"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Adapty = void 0;
var tslib_1 = require("tslib");
var utils_1 = require("../utils");
var error_1 = require("./error");
var events_1 = require("./events");
var paywall_1 = require("./paywall");
var profile_1 = require("./profile");
var promo_1 = require("./promo");
var purchases_1 = require("./purchases");
var Adapty = /** @class */ (function (_super) {
    tslib_1.__extends(Adapty, _super);
    function Adapty() {
        var _this = _super.call(this) || this;
        _this.ctx = {
            module: (0, utils_1.extractModule)(),
            isActivated: false,
            sdkKey: undefined,
            observerMode: false,
            customerUserId: undefined,
        };
        _this.profile = new profile_1.Profile(_this.ctx);
        _this.purchases = new purchases_1.Purchases(_this.ctx);
        _this.promo = new promo_1.Promo(_this.ctx);
        _this.paywalls = new paywall_1.AdaptyPaywalls(_this.ctx);
        return _this;
    }
    /**
     * @static @private
     * It is being used to define wether
     * a user has activated the SDK using
     * @function activateAdapty or @function useAdapty
     */
    Adapty.activateSdk = function (consumer, sdkKey) {
        consumer.ctx.isActivated = true;
        consumer.ctx.sdkKey = sdkKey;
    };
    Adapty.prototype.getApnsToken = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                try {
                    return [2 /*return*/, this.ctx.module.getAPNSToken()];
                }
                catch (error) {
                    throw (0, error_1.attemptToDecodeError)(error);
                }
                return [2 /*return*/];
            });
        });
    };
    Adapty.prototype.setApnsToken = function (token) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                try {
                    this.ctx.module.setAPNSToken(token);
                }
                catch (error) {
                    throw (0, error_1.attemptToDecodeError)(error);
                }
                return [2 /*return*/];
            });
        });
    };
    Adapty.prototype.setIsExternalAnalyticsEnabled = function (isEnabled) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                try {
                    this.ctx.module.setExternalAnalyticsEnabled(isEnabled);
                }
                catch (error) {
                    throw (0, error_1.attemptToDecodeError)(error);
                }
                return [2 /*return*/];
            });
        });
    };
    /**
     * Updates
     *
     * @throws AdaptyError
     */
    Adapty.prototype.updateAttribution = function (networkUserId, attribution, source) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                (0, error_1.isSdkAuthorized)(this.ctx.isActivated);
                try {
                    return [2 /*return*/, this.ctx.module.updateAttribution(attribution, source, networkUserId)];
                }
                catch (error) {
                    throw (0, error_1.attemptToDecodeError)(error);
                }
                return [2 /*return*/];
            });
        });
    };
    return Adapty;
}(events_1.AdaptyEventEmitter));
exports.Adapty = Adapty;
