"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.activateAdapty = void 0;
var tslib_1 = require("tslib");
var adapty_1 = require("./adapty");
var sdk_1 = require("./sdk");
var error_1 = require("./sdk/error");
var utils_1 = require("./utils");
/**
 *
 * This function makes it possible
 * to use Adapty SDK, consider placing it
 * inside a core `useEffect` or a core `didComponentMount`
 *
 * @example
 * useEffect(() => {
 *   activateAdapty({
 *     sdkKey: "Public_sdk_key",
 *     customerUserId: myUser.id
 *   });
 * },[myUser.id]);
 *
 *
 * This function should be called before using an SDK.
 * For more information about arguments look for an @interface AdaptyActivateArgs
 */
function activateAdapty(props) {
    var _a;
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var module, userId, DEFAULT_LOG_LEVEL, logLevel, sdkKey, DEFAULT_OBSERVER_MODE, isModeObserver, error_2;
        return tslib_1.__generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    module = (0, utils_1.extractModule)();
                    userId = ((_a = props.customerUserId) === null || _a === void 0 ? void 0 : _a.toString()) || null;
                    DEFAULT_LOG_LEVEL = 'none';
                    logLevel = props.logLevel || DEFAULT_LOG_LEVEL;
                    sdkKey = props.sdkKey;
                    if (!sdkKey) {
                        throw new error_1.AdaptyError({
                            adaptyCode: 'sdkNotActive',
                            code: 400,
                            localizedDescription: 'SDK private key was not passed',
                        });
                    }
                    DEFAULT_OBSERVER_MODE = false;
                    isModeObserver = props.observerMode !== undefined
                        ? props.observerMode
                        : DEFAULT_OBSERVER_MODE;
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, module.activate(sdkKey, userId, isModeObserver, logLevel)];
                case 2:
                    _b.sent();
                    return [4 /*yield*/, sdk_1.Adapty.activateSdk(adapty_1.adapty, sdkKey)];
                case 3:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 4:
                    error_2 = _b.sent();
                    throw error_2;
                case 5: return [2 /*return*/];
            }
        });
    });
}
exports.activateAdapty = activateAdapty;
