"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdaptyEventEmitter = void 0;
var react_native_1 = require("react-native");
var AdaptyEventEmitter = /** @class */ (function () {
    function AdaptyEventEmitter() {
        this.addEventListener = this.addListener;
        this.nativeEmitter = new react_native_1.NativeEventEmitter(react_native_1.NativeModules.RNAdapty);
        this.listeners = [];
    }
    /**
     * Adapty Event Handler
     *
     * @param type defines which event you are listening to
     * @param callback defines what action would be called, when event fired
     */
    AdaptyEventEmitter.prototype.addListener = function (event, callback) {
        var parseCallback = function () {
            var data = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                data[_i] = arguments[_i];
            }
            var args = data.map(function (arg) { return JSON.parse(arg); });
            callback.apply(null, args);
        };
        var subscription = this.nativeEmitter.addListener(event, parseCallback);
        this.listeners.push(subscription);
        return subscription;
    };
    AdaptyEventEmitter.prototype.removeAllListeners = function () {
        this.listeners.forEach(function (listener) { return listener.remove(); });
        this.listeners = [];
    };
    return AdaptyEventEmitter;
}());
exports.AdaptyEventEmitter = AdaptyEventEmitter;
