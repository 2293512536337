"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Purchases = void 0;
var tslib_1 = require("tslib");
var react_native_1 = require("react-native");
var error_1 = require("./error");
var Purchases = /** @class */ (function () {
    function Purchases(context) {
        this.ctx = context;
    }
    /**
     * Use to restore purchases on a new device.
     * Purchases will appear in user's purchasesInfo
     *
     * @throws {@link AdaptyError}
     */
    Purchases.prototype.restore = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var json, result, error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, error_1.isSdkAuthorized)(this.ctx.isActivated);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.ctx.module.restorePurchases()];
                    case 2:
                        json = _a.sent();
                        result = JSON.parse(json);
                        return [2 /*return*/, result];
                    case 3:
                        error_2 = _a.sent();
                        throw (0, error_1.attemptToDecodeError)(error_2);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Updates any available fields to a current user
     *
     * @returns Promised
     * @throws {@link AdaptyError}
     */
    Purchases.prototype.getInfo = function (options) {
        if (options === void 0) { options = {}; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var json, result, error_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        (0, error_1.isSdkAuthorized)(this.ctx.isActivated);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.ctx.module.getPurchaseInfo(options)];
                    case 2:
                        json = _a.sent();
                        result = JSON.parse(json);
                        return [2 /*return*/, result];
                    case 3:
                        error_3 = _a.sent();
                        throw (0, error_1.attemptToDecodeError)(error_3);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * @throws AdaptyError
     */
    Purchases.prototype.makePurchase = function (product, params) {
        var _a, _b, _c;
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var json, result, error_4;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        (0, error_1.isSdkAuthorized)(this.ctx.isActivated);
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.ctx.module.makePurchase(product.vendorProductId, product.variationId, react_native_1.Platform.select({
                                ios: (_a = params === null || params === void 0 ? void 0 : params.ios) === null || _a === void 0 ? void 0 : _a.offerId,
                                macos: (_b = params === null || params === void 0 ? void 0 : params.ios) === null || _b === void 0 ? void 0 : _b.offerId,
                                android: (_c = params === null || params === void 0 ? void 0 : params.android) === null || _c === void 0 ? void 0 : _c.subscriptionUpdateParam,
                            }))];
                    case 2:
                        json = _d.sent();
                        result = JSON.parse(json || '');
                        return [2 /*return*/, result];
                    case 3:
                        error_4 = _d.sent();
                        throw (0, error_1.attemptToDecodeError)(error_4);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Associates transaction with a certain paywall
     */
    Purchases.prototype.setVariationId = function (variationId, transactionId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.ctx.module.setVariationID(variationId, transactionId)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        error_5 = _a.sent();
                        throw (0, error_1.attemptToDecodeError)(error_5);
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return Purchases;
}());
exports.Purchases = Purchases;
